import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import house from '../../../assets/img/about/breadcrumb.jpg';

class Breadcrumb extends Component {
  render() {
    return (
      <section className="breadcrumb-area" style={{backgroundImage: `url(${house})`}}>
          <div className="container">
            <div className="breadcrumb-text">
              <span>Conózcanos</span>
              <h2 className="page-title">Nosotros</h2>
            </div>
          </div>
        </section>
    );
  }
}

export default Breadcrumb;
