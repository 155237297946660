import React, { Component } from 'react'
import Headerthree from '../layouts/Headerthree';
import Footertwo from '../layouts/Footertwo';
import Breadcrumb from '../sections/institutional/Breadcrumb';
import BoutiquePage from '../sections/boutique/Boutique';
import SchoolGallery from './SchoolGallery';
import CoreFeature from '../sections/homepage-three/Corefeature';

class Institutional extends Component {
  render() {
    return (
      <div>
				<Headerthree />
        <Breadcrumb/>
        <CoreFeature/>
        <SchoolGallery />
        <Footertwo />
      </div>
    );
  }
}

export default Institutional;
