import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactWOW from "react-wow";
import p1 from "../../../assets/img/institutional/Escuela_3.jpg";
import p2 from "../../../assets/img/institutional/Escuela_1.jpg";
import p3 from "../../../assets/img/institutional/Escuela_5.jpg";

class Corefeature extends Component {
	render() {
		return (
			<section className="core-feature-section bg-white pt-115 pb-115 pb-115-special">
				<div className="container">
					<div className="section-title text-center mb-50">
						<h2>Trabajamos con escuelas y empresas día a día</h2>
					</div>
					{/* Featre Loop */}
					<div className="row features-loop">
						<div className="col-lg-4 col-sm-6 order-1">
							<ReactWOW animation="fadeInLeft" data-wow-delay=".3s">
								<div className="feature-box">
									<p>
										Diariamente preparamos un <strong>menú balanceado</strong> para alrededor de
										<strong> 6.000</strong> niños que almuerzan en escuelas e instituciones
										públicas de ANEP.
									</p>
								</div>
							</ReactWOW>
						</div>
						<div className="col-lg-4 col-sm-6 order-2">
							<ReactWOW animation="fadeInDown" data-wow-delay=".4s">
								<div className="feature-box with-hover-img" style={{ height: '40vh'}}>
                <div
										className="hover-img"
										style={{
											backgroundImage: `url(${p1})`,
										}}
									/>
								</div>
							</ReactWOW>
						</div>
						<div className="col-lg-4 col-sm-6 order-3 order-sm-4 order-lg-3">
							<ReactWOW animation="fadeInRight" data-wow-delay=".5s">
								<div className="feature-box">
									<p>
										Los platos son elaborados con los más estrictos <strong>controles de
										calidad</strong> y entregados todos los días para que conserven todos
										los <strong>nutrientes</strong>.
									</p>
								</div>
							</ReactWOW>
						</div>
						<div className="col-lg-4 col-sm-6 order-4 order-sm-3 order-lg-4">
							<ReactWOW animation="fadeInLeft" data-wow-delay=".6s">
              <div className="feature-box with-hover-img" style={{ height: '40vh'}}>
                <div
										className="hover-img"
										style={{
											backgroundImage: `url(${p2})`,
										}}
									/>
								</div>
							</ReactWOW>
						</div>
						<div className="col-lg-4 col-sm-6 order-5">
							<ReactWOW animation="fadeInUp" data-wow-delay=".7s">
								<div className="feature-box">
									<p><strong>Comedores Escolares:</strong> Woodlands</p>
									<p>
										<strong>Empresas:</strong> hay varias empresas que confían en nosotros el
										almuerzo de su personal. Hacemos diferentes <strong>menúes semanales </strong>
										elaborados con nutricionistas.
									</p>
								</div>
							</ReactWOW>
						</div>

						<div className="col-lg-4 col-sm-6 order-6">
							<ReactWOW animation="fadeInRight" data-wow-delay=".8s">
              <div className="feature-box with-hover-img" style={{ height: '40vh'}}>
                <div
										className="hover-img"
										style={{
											backgroundImage: `url(${p3})`,
										}}
									/>
								</div>
							</ReactWOW>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default Corefeature;
