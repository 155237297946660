import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import food from '../../../assets/img/institutional/Institucional-1.jpg';

class Breadcrumb extends Component {
  render() {
    return (
      <section className="breadcrumb-area-soft-shade" style={{backgroundImage: `url(${food})`}}>
          <div className="container">
            <div className="breadcrumb-text">
              <span>Conózcanos más</span>
              <h2 className="page-title">Cocina institucional</h2>
            </div>
          </div>
        </section>
    );
  }
}

export default Breadcrumb;
