import React, { Component } from 'react'
import Headerthree from '../layouts/Headerthree';
import Footertwo from '../layouts/Footertwo';
import Breadcrumb from '../sections/boutique/Breadcrumb';
import BoutiquePage from '../sections/boutique/Boutique';
import Gallery from './Gallery';
import Roomslider from '../sections/homepage-one/Roomslider';

class Boutique extends Component {
  render() {
    return (
      <div>
				<Headerthree />
        <Breadcrumb/>
        <BoutiquePage/>
        <Gallery />
        <Footertwo />
      </div>
    );
  }
}

export default Boutique;
