import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactWOW from "react-wow";
import planta from "../../../assets/img/about/planta0.jpg";
class Plant extends Component {
	render() {
		return (
			<section className="text-block pt-115 pb-115 no-pad-top-mobile">
				<div className="container">
					<ReactWOW animation="fadeInLeft" data-wow-delay=".3s">
						<div className="row align-items-center justify-content-center">
							<ReactWOW animation="fadeInRight" data-wow-delay=".5s">
								<div className="col-lg-5 col-md-8 col-sm-10">
									<div className="block-text">
										<div className="section-title mb-20">
											<h3>Nuestra planta.</h3>
										</div>
										<p style={{ margin: "10px 0px" }}>
											En el año 2017 inauguramos nuestra planta de elaboración con
											tecnología de vanguardia y cerca de{" "}
											<strong>3000 m2</strong>, de modo de contar con una mayor
											superficie destinada a la elaboración.
										</p>
										<p style={{ margin: "10px 0px" }}>
											{" "}
											Incorporamos nuevas tecnologías que permiten
											<strong> optimizar los procesos</strong> mejorando la{" "}
											<strong>eficiencia</strong> y <strong>calidad </strong>
											del total de productos que se elaboran.
										</p>
										<p style={{ margin: "10px 0px" }}>
											{" "}
											También cuenta con área de{" "}
											<strong>
												panadería, repostería, zona de bocaditos, cocina
												artesanal
											</strong>
											, túneles de enfriamiento y cámaras frigoríficas.
										</p>
										<p style={{ margin: "10px 0px" }}>
											{" "}
											Además transportamos nuestros productos hasta el lugar del
											evento en contenedores isotérmicos que garantizan un
											producto en <strong>excelentes condiciones</strong> en el
											momento de ser degustado.
										</p>
									</div>
								</div>
							</ReactWOW>
							<div className="col-lg-7">
								<div className="text-img text-center text-lg-left mb-small">
									<img src={planta} alt="" />
								</div>
							</div>
						</div>
					</ReactWOW>
				</div>
			</section>
		);
	}
}

export default Plant;
