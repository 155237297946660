import React, { Component } from "react";
import Headerthree from "../layouts/Headerthree";
import Footertwo from "../layouts/Footertwo";
import Breadcrumb from "../sections/business/Breadcrumb";
import BusinessPage from "../sections/business/Business";
import Slider from "../sections/weddings/Slider";

import roomimg8 from "../../assets/img/events/business/Galeria_Empresariales_3-1.jpg";
import roomimg9 from "../../assets/img/events/business/Galeria_Empresariales_4-1.jpg";
import roomimg10 from "../../assets/img/events/business/Galeria_Empresariales_5-1.jpg";
import roomimg11 from "../../assets/img/events/business/empresariales4-min.jpg";
import roomimg12 from "../../assets/img/events/business/Galeria_Empresariales_9.jpg";
import roomimg13 from "../../assets/img/events/business/empresariales1-min.jpg";
import roomimg14 from "../../assets/img/events/business/empresariales2-min.jpg";
import roomimg16 from "../../assets/img/events/business/empresariales3-min.jpg";
import roomimg15 from "../../assets/img/events/business/Galeria_Empresariales_8.jpg";


const roomimgPostsRegency = [
	{
		photo: roomimg8,
	},
	{
		photo: roomimg9,
	},
	{
		photo: roomimg10,
	},
	{
		photo: roomimg11,
	},
	{
		photo: roomimg12,
	},
	{
		photo: roomimg13,
	},
	{
		photo: roomimg14,
	},
	{
		photo: roomimg15,
	},
	{
		photo: roomimg16,
	},
];


const roomPostsRegency = [
	{
		name: "Eventos empresariales",
		desc: "",
	},
	{
		name: "Eventos empresariales",
		desc: "",
	},
	{
		name: "Eventos empresariales",
		desc: "",
	},
	{
		name: "Eventos empresariales",
		desc: "",
	},
	{
		name: "Eventos empresariales",
		desc: "",
	},
	{
		name: "Eventos empresariales",
		desc: "",
	},
	{
		name: "Eventos empresariales",
		desc: "",
	},
	{
		name: "Eventos empresariales",
		desc: "",
	},
	{
		name: "Eventos empresariales",
		desc: "",
	},
	{
		name: "Eventos empresariales",
		desc: "",
	},
	{
		name: "Eventos empresariales",
		desc: "",
	},
	{
		name: "Eventos empresariales",
		desc: "",
	},
];



class About extends Component {
	render() {
		return (
			<div>
				<Headerthree />
				<Breadcrumb />
				<BusinessPage />
				<Slider
					roomimgPosts={roomimgPostsRegency}
					roomPosts={roomPostsRegency}
				/>

				<Footertwo />
			</div>
		);
	}
}

export default About;
