import React, { Component } from 'react'
import Headerthree from '../layouts/Headerthree';
import Footertwo from '../layouts/Footertwo';
import Breadcrumb from '../sections/about/Breadcrumb';
import Aboutpage from '../sections/about/About';
import Roomslider from '../sections/homepage-one/Roomslider';
import Plant from "../sections/homepage-two/TextblockAlt";

import roomimg5 from "../../assets/img/about/planta1.jpg";
import roomimg1 from "../../assets/img/about/planta2.jpg";
import roomimg3 from "../../assets/img/about/planta3.jpg";
import roomimg4 from "../../assets/img/about/planta4.jpg";
import roomimg2 from "../../assets/img/about/planta5.jpg";
import galleryimg1 from "../../assets/img/about/about6.jpg";
import galleryimg2 from "../../assets/img/about/about2.jpg";
import galleryimg3 from "../../assets/img/about/about4.jpg";
import galleryimg4 from "../../assets/img/about/about3.jpg";
import galleryimg5 from "../../assets/img/about/about5.jpg";
import galleryimg6 from "../../assets/img/about/about1.jpg";

import img1 from "../../assets/img/about/planta6.jpg";
import img2 from "../../assets/img/about/planta7.jpg";
import img3 from "../../assets/img/about/planta8.jpg";
import img4 from "../../assets/img/about/planta9.jpg";
import img5 from "../../assets/img/about/planta10.jpg";
import img6 from "../../assets/img/about/planta11.jpg";

const postsOne = [
	{
		photo: roomimg1,
	},
	{
		photo: galleryimg1,
	},
	{
		photo: galleryimg2,
	},
	{
		photo: galleryimg3,
	},
	{
		photo: galleryimg4,
	},
	{
		photo: galleryimg5,
	},
	{
		photo: galleryimg6,
	},
	{
		photo: roomimg2,
	},
	{
		photo: roomimg3,
	},
	{
		photo: roomimg4,
	},
	{
		photo: roomimg5,
	},
];

const postsTwo = [
	{
		photo: img1,
	},
	{
		photo: img2,
	},
	{
		photo: img3,
	},
	{
		photo: img4,
	},
	{
		photo: img5,
	},
	{
		photo: img6,
	},
	
];


class About extends Component {
  render() {
    return (
      <div>
				<Headerthree />
        <Breadcrumb/>
        <Aboutpage/>
        <Roomslider posts={postsOne}/>
        <Plant />
        <Roomslider posts={postsTwo}/>
        <Footertwo />
      </div>
    );
  }
}

export default About;
