import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactWOW from "react-wow";
import family from '../../../assets/img/about/family.jpg';
class Family extends Component {
	render() {
		return (
			<section className="text-block pt-115 pb-115">
				<div className="container">
					<ReactWOW animation="fadeInLeft" data-wow-delay=".3s">
						<div className="row align-items-center justify-content-center">
							<div className="col-lg-7">
								<div className="text-img text-center text-lg-left mb-small">
									<img src={family} alt="" />
								</div>
							</div>
							<ReactWOW animation="fadeInRight" data-wow-delay=".5s">
								<div className="col-lg-5 col-md-8 col-sm-10">
									<div className="block-text">
										<div className="section-title mb-20">
											<h3>
												Nora Rey Traiteur es una empresa familiar dedicada a la
												alta gastronomía.
											</h3>
										</div>
										<p style={{margin: '10px 0px'}}>Nuestras propuestas son <strong>exclusivas</strong> para cada evento.</p>
										<p style={{margin: '10px 0px'}}>
											{" "}
											En las reuniones previas al mismo compartimos inquietudes
											y expectativas, llegando así a crear un <strong>vínculo de
											confianza </strong>que garantiza el éxito en la fiesta.
										</p>
										<p style={{margin: '10px 0px'}}>
											{" "}
											Nuestra meta es superar las expectativas de cada persona
											que <strong>confía en nosotros</strong>.
										</p>
										<p style={{margin: '10px 0px'}}>
											{" "}
											Contamos con un staff de más de <strong>120 empleados</strong>; personal de
											cocina, técnicos y administrativos, todos entrenados para
											cumplir con las técnicas y normas de higiene necesarias en
											la industria gastronómica.
										</p>
									</div>
								</div>
							</ReactWOW>
						</div>
					</ReactWOW>
				</div>
			</section>
		);
	}
}

export default Family;
