import React, { Component } from "react";
import Headerthree from "../layouts/Headerthree";
import Footertwo from "../layouts/Footertwo";
import Breadcrumb from "../sections/familiars/Breadcrumb";
import FamiliarsPage from "../sections/familiars/Familiars";
import Slider from "../sections/weddings/Slider";

import roomimg8 from "../../assets/img/events/family/Galeria_Familiares_3.jpg";
import roomimg9 from "../../assets/img/events/family/Galeria_Familiares_2.jpg";
import roomimg10 from "../../assets/img/events/family/Galeria_Familiares_8.jpg";
import roomimg11 from "../../assets/img/events/family/Galeria_Familiares_0.jpg";
import roomimg12 from "../../assets/img/events/family/Galeria_Familiares_5.jpg";
import roomimg13 from "../../assets/img/events/family/Galeria_Familiares_10.jpg";
import roomimg14 from "../../assets/img/events/family/Galeria_Familiares_7.jpg";
import roomimg15 from "../../assets/img/events/family/Galeria_Familiares_11.jpg";
import roomimg16 from "../../assets/img/events/family/Galeria_Familiares_12.png";
import roomimg17 from "../../assets/img/events/family/Galeria_Familiares_13.jpg";
import roomimg18 from "../../assets/img/events/family/Galeria_Familiares_9.jpg";

const roomimgPostsRegency = [
	{
		photo: roomimg8,
	},
	{
		photo: roomimg9,
	},
	{
		photo: roomimg10,
	},
	{
		photo: roomimg11,
	},
	{
		photo: roomimg12,
	},
	{
		photo: roomimg13,
	},
	{
		photo: roomimg14,
	},
	{
		photo: roomimg15,
	},
	{
		photo: roomimg16,
	},
	{
		photo: roomimg17,
	},
	{
		photo: roomimg18,
	},
];


const roomPostsRegency = [
	{
		name: "Eventos familiares",
		desc: "",
	},
	{
		name: "Eventos familiares",
		desc: "",
	},
	{
		name: "Eventos familiares",
		desc: "",
	},
	{
		name: "Eventos familiares",
		desc: "",
	},
	{
		name: "Eventos familiares",
		desc: "",
	},
	{
		name: "Eventos familiares",
		desc: "",
	},
	{
		name: "Eventos familiares",
		desc: "",
	},
	{
		name: "Eventos familiares",
		desc: "",
	},
	{
		name: "Eventos familiares",
		desc: "",
	},
	{
		name: "Eventos familiares",
		desc: "",
	},
	{
		name: "Eventos familiares",
		desc: "",
	},
	{
		name: "Eventos familiares",
		desc: "",
	},
];



class About extends Component {
	render() {
		return (
			<div>
				<Headerthree />
				<Breadcrumb />
				<FamiliarsPage />
				<Slider
					roomimgPosts={roomimgPostsRegency}
					roomPosts={roomPostsRegency}
				/>

				<Footertwo />
			</div>
		);
	}
}

export default About;
