import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import SRLWrapper from "simple-react-lightbox";

import dishimg1 from "../../../assets/img/menu/menu-gallery-1.jpg";
import dishimg2 from "../../../assets/img/menu/menu-gallery-2.jpg";
import dishimg3 from "../../../assets/img/menu/menu-gallery-3.jpg";

function SampleNextArrow(props) {
	const { onClick } = props;
	return (
		<div className="slick-arrow next-arrow" onClick={onClick}>
			<i className="fal fa-arrow-right"></i>
		</div>
	);
}

function SamplePrevArrow(props) {
	const { onClick } = props;
	return (
		<div className="slick-arrow prev-arrow" onClick={onClick}>
			<i className="fal fa-arrow-left"></i>
		</div>
	);
}

class Menuarea extends Component {
	constructor(props) {
		super(props);
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
	}

	next() {
		this.slider.slickNext();
	}
	previous() {
		this.slider.slickPrev();
	}

	render() {
		const settings = {
			slidesToShow: 1,
			slidesToScroll: 1,
			fade: false,
			infinite: true,
			autoplay: true,
			autoplaySpeed: 6000,
			arrows: false,
			dots: false,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
		};
		return (
			<section
				id="menu"
				className="menu-area bg-white menu-with-shape menu-nagative-gallery"
				style={{ height: window.innerWidth < 1020 ? "auto" : "120vh" }}
			>
				<div className="container">
					{/* section title */}
					<div className="row align-items-center">
						<div className="col-md-8 col-sm-8">
							<div className="section-title">
								<span className="title-tag"></span>
								<h2>Menú</h2>
							</div>
						</div>
						<div className="col-md-4 col-sm-4 d-none d-sm-block">
							<div className="menu-slider-arrow arrow-style text-right">
								<SamplePrevArrow onClick={this.previous} />
								<SampleNextArrow onClick={this.next} />
							</div>
						</div>
					</div>
					{/* Menu Slider */}
					<Slider
						className="menu-slider mt-80 menu-loop"
						{...settings}
						ref={(c) => (this.slider = c)}
					>
						<div className="single-slider">
							<div className="row justify-content-center">
								<h3
									style={{ marginBottom: "25px", textDecoration: "underline" }}
								>
									Copetín
								</h3>
							</div>
							<div className="row justify-content-center">
								<div className="col-lg-4 col-md-10">
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Empanada de carne</h4>
											<p>
												{" "}
												Empanada de carne picada, aceitunas, huevo, cebolla. 
												*Sugerimos calentar en el horno.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Empanada de choclo</h4>
											<p>
												{" "}
												Empanada de choclo, cebolla y queso parmesano. *Sugerimos
												calentar en el horno.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Empanada de jamón y queso</h4>
											<p>
												Empanada de jamón y variedades de queso: parmesano,
												colonia y dambo.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Empanada de pollo</h4>
											<p>
												Empanada de pollo, cebolla, panceta, manzana verde. 
												*Sugerimos calentar en el horno.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Hummus</h4>
											<p>Dip de garbanzos, tahini, aceite de oliva y limón.</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Paté de foie</h4>
											<p>Paté de higado de ave, crema, manteca y cognac.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-10">
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Paté de salmón</h4>
											<p>Salmón cocido, salmón ahumado, manteca, crema.</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Quesito de nuez</h4>
											<p>
												Mezcla de variedades de queso: Ricotta, untable,
												parmesano y dambo con nuez.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Palmitas de hojaldre</h4>
											<p>Palmitas de hojaldre de manteca con azúcar.</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Quesito de pimienta</h4>
											<p>
												Mezcla de variedades de queso: Ricotta, untable,
												parmesano y dambo con pimienta negra por fuera.
											</p>
										</div>
									</div>{" "}
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Quesito de hierbas</h4>
											<p>
												Mezcla de variedades de queso: Ricotta, untable,
												parmesano y dambo con ciboulette y perejil.
											</p>
										</div>
									</div>{" "}
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Sandwiches de pan de nuez</h4>
											<p>
												Pan de nuez, jamón y queso dambo.
											</p>
										</div>
									</div>{" "}
								</div>
								<div className="col-lg-4 col-md-10">

									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Focaccias</h4>
											<p>Galleta larga con sésamo o parmesano.</p>
										</div>
									</div>{" "}
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Galletas finitas</h4>
											<p>Galleta finita para untar.</p>
										</div>
									</div>{" "}
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Tostaditas</h4>
											<p>Tostaditas cuadradas de pan blanco.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="single-slider">
							<div className="row justify-content-center">
								<h3
									style={{ marginBottom: "25px", textDecoration: "underline" }}
								>
									Tartas
								</h3>
							</div>

							<div className="row justify-content-center">
								<div className="col-lg-4 col-md-10">
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Tarta Caprese</h4>
											<p>
												{" "}
												Tarta de queso dambo, ricotta, tomates confitados y
												albahaca.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Tarta Calabaza y Panceta</h4>
											<p> Tarta de calabaza, ciboulette y panceta.</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Tarta Cebolla y Queso</h4>
											<p>Tarta de cebolla, queso dambo y tomillo.</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Tarta de Espinaca y Ricotta</h4>
											<p>Tarta de cebolla, espinaca y Ricotta.</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Tarta de Jamon y Queso</h4>
											<p>Tarta de jamón y queso dambo.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-10">
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Tarta de Puerros</h4>
											<p>Puerros.</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Tarta Ratatouille</h4>
											<p>
												Tarta de verduras: cebolla, morron rojo, morron verde,
												zapallito, berenjena, tomate, queso parmesano.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Tarta zapallito</h4>
											<p>Tarta de zapallitos y queso parmesano.</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Tarta Integral de Atún</h4>
											<p>
												Tarta con masa integral de cebolla, morrón rojo, atún y
												perejil.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Tarta Integral de Calabaza y Panceta</h4>
											<p>
												Tarta con masa integral de calabaza, panceta, y
												ciboulette.
											</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-10">
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Tarta Integral de Zapallito</h4>
											<p>Tarta con masa integral de zapallito.</p>
										</div>
									</div>{" "}
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Tarta dulce de lomito y queso</h4>
											<p>Tarta con masa dulce, lomito y queso dambo.</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Tarta Integral de Puerro</h4>
											<p>Tarta con masa integral de puerro.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="single-slider">
							<div className="row justify-content-center">
								<h3
									style={{ marginBottom: "25px", textDecoration: "underline" }}
								>
									Congelados
								</h3>
							</div>
							<div className="row justify-content-center">
								<div className="col-lg-3 col-md-10">
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Berenjenas a la parmesana</h4>
											<p>
												{" "}
												Fetas de berenjenas grilladas con salsa de tomate fresco
												y albahaca, muzarella y parmesano.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Bondiola ahumada con salsa de puerros</h4>
											<p>
												{" "}
												Pulpa de bondiola ahumada y especiada, con salsa cremosa
												de puerros y mostaza.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Papas a la suiza</h4>
											<p>
												Papas ralladas crocantes, con tomillo fresco y cebolla,
												gratinadas.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Canelones de carne</h4>
											<p>
												Crepes con relleno de carne, salsa bechamel, culis de
												tomate y queso parmesano.
											</p>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-10">
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Canelones de choclo con salsa verde</h4>
											<p>
												Crepes con relleno de choclo y jamón con salsa cremosa
												de espinacas.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Pollo chino con almendras</h4>
											<p>
												Pollo en bocados y vegetales saltados al wok, con
												almendras tostadas y salsa agridulce al estilo oriental.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Canelones de verdura</h4>
											<p>
												Crepes con relleno de espinaca y acelga, salsa bechamel,
												culis de tomate y queso parmesano.
											</p>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-10">
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Carne a la pimienta verde</h4>
											<p>
												Corazón de cuadril en tiritas con salsa cremosa
												aromatizada con pimienta verde.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Cazuela de lentejas vegana</h4>
											<p>
												Cazuela en base a lentejas y vegetales (apio, puerro,
												zanahoria y tomate) Con papas, boniatos y especias
												aromáticas.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Cazuela de mondongo</h4>
											<p>
												Cazuela de mondongo con papas, garbanzos, carne vacuna,
												pechito de cerdo, chorizo colorado y vegetales
												aromáticos.
											</p>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-10">
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Chicken pie</h4>
											<p>
												Guisado pollo, panceta, huevo y arvejas, cubierta con
												masa de hojaldre crocante.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Croquetas de arroz</h4>
											<p>
												En base arroz, bechamel, con trocitos de jamón cocido, y
												perejil picado.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Croquetas de papa</h4>
											<p>
												En base a puré de papas, con queso dambo y ciboulette.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Feijoada</h4>
											<p>
												Cazuela de porotos negros, con carne vacuna, carne de
												cerdo, chorizo colorado, vegetales aromáticos y
												especias.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div>
							<div className="row justify-content-center">
								<h3
									style={{ marginBottom: "25px", textDecoration: "underline" }}
								>
									Congelados
								</h3>
							</div>
							<div className="row justify-content-center">
								<div className="col-lg-4 col-md-10">
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Carbonada criolla</h4>
											<p>
												Cazuela en base a vegetales (morrón rojo y verde,
												zanahoria, choclo, zapallo y tomate) , carne vacuna y
												carne de cerdo, con papas, boniatos y peras.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Cerdo agridulce</h4>
											<p>
												Solomillo de cerdo en tiritas, con vegetales salteados
												al wok, con trocitos de ananá, miel y jengibre con salsa
												agridulce.
											</p>
										</div>
									</div>{" "}
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Rótolos de espinaca</h4>
											<p>
												Arrollado de masa verde relleno de espinaca y ricotta,
												con salsa bechamel, parmesano y culis de tomate.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Lasagnas de carne</h4>
											<p>
												Lasagna con masa de espinaca, rellena con tuco de carne
												cremoso, con hongos Jamón cocido, queso dambo y queso
												parmesano.
											</p>
										</div>
									</div>{" "}
								</div>
								<div className="col-lg-4 col-md-10">
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Lasagnas de pollo</h4>
											<p>
												Lasagna con masa de espinaca, rellena con tuco de pollo
												cremoso, con hongos jamón cocido, queso dambo y queso
												parmesano.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Lasagna de vegetales</h4>
											<p>
												Lasagna con masa de espinaca, rellena con crema de
												tomate fresco y albahaca Verduras grilladas ( zuchinis,
												tomate y berenjena), queso dambo y parmesano.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Pollo a la crema de champignon</h4>
											<p>
												Pollo en bocados, con salsa cremosa de champignon fresco.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Pollo al curry</h4>
											<p>
												Pollo en bocados con salsa cremosa de curry, tomate,
												manzanas y pasas de uva.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="single-slider">
							<div className="row justify-content-center">
								<h3
									style={{ marginBottom: "25px", textDecoration: "underline" }}
								>
									Dulces
								</h3>
							</div>
							<div className="row justify-content-center">
								<div className="col-lg-3 col-md-10">
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Alfajor de harina de arroz</h4>
											<p>
												{" "}
												Alfajor de chocolate con harina de arroz y maicena
												relleno de dulce de leche.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Alfajor de maicena</h4>
											<p>
												{" "}
												Alfajor de maicena relleno de dulce de leche y coco por
												fuera.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Alfajor de tres capas</h4>
											<p>
												Alfajor de finas capas crocantes rellenas de dulce de
												leche con baño de glacé.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Alfajores ovalados chocolate</h4>
											<p>
												Alfajor relleno de dulce de leche con baño de chocolate.
											</p>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-10">
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Barrita de cereales</h4>
											<p>
												Barrita de copos de maíz, almendras, nueces, sésamo,
												lino y miel.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Galletitas de coco</h4>
											<p>Galletas crocantes de coco.</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Plantillas</h4>
											<p>Clásicas plantillas (o vainillas) azucaradas.</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Torta de naranja</h4>
											<p>Budín humedo de naranja.</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Postre mousse de chocolate</h4>
											<p>
												Base de pionono de chocolate, mousse de chocolate
												blanco, leche, negro cubierto con ganache y decorado con
												nueces.
											</p>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-10">
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Torta de zanahoria y manzana</h4>
											<p>Torta de zanahoria, manzana, nueces y canela.</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Bombones surtidos</h4>
											<p>
												Chocolate negro semi amargo, leche, o blanco, rellenos
												de dulce de leche con nueces, dulce de café, ganache de
												chocolate o mermeladas.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Eclaire de dulce de leche</h4>
											<p>Bomba rellena de dulce de leche.</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Eclaire de chocolate</h4>
											<p>Bomba rellena de crema pastelera de chocolate.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-10">
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Eclaire de crema</h4>
											<p>Bomba rellena de crema pastelera de vainilla.</p>
										</div>
									</div>{" "}
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Eclaire de sambayón</h4>
											<p>Bomba rellena de crema de sambayón.</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Eclaire de café</h4>
											<p>Bomba rellena de crema pastelera de café.</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Macaron de chocolate</h4>
											<p>
												Galletas de merengue y almendras rellenas de ganache de
												chocolate.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Torta rogel</h4>
											<p>
												Finas capas de masa crocante rellenas de dulce de leche
												decorado con merengue.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="single-slider">
							<div className="row justify-content-center">
								<h3
									style={{ marginBottom: "25px", textDecoration: "underline" }}
								>
									Dulces
								</h3>
							</div>
							<div className="row justify-content-center">
								<div className="col-lg-3 col-md-10">
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Macaron de limón</h4>
											<p>
												Galletas de merengue y almendras rellenas de crema de
												limón.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Macaron de maracuyá</h4>
											<p>
												Galletas de merengue y almendras rellenas de crema de
												maracuyá.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Macaron de café</h4>
											<p>
												Galletas de merengue y almendras rellenas de dulce de
												café.
											</p>
										</div>
									</div>{" "}
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Macaron de pistacho </h4>
											<p>
												Galletas de merengue y almendras rellenas de ganache de
												pistacho.
											</p>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-10">
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Pasta frola </h4>
											<p>Clásica pasta frola de membrillo o dulce de leche.</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Cookies con chispas chocolate </h4>
											<p>Galletas con chispas de chocolate, avena y nueces.</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Palmitas </h4>
											<p>Palmitas hojaldradas de manteca y azúcar.</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Brownie </h4>
											<p>Brownie clásico con nueces.</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Tarta de frutas variadas</h4>
											<p>Tarta de crema pastelera, y frutas variadas.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-10">
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Yo Yo de chocolate </h4>
											<p>
												Alfajor de masa tierna, relleno de dulce de leche con
												baño de chocolate.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Brownie con ganache, dulce y merengue</h4>
											<p>
												{" "}
												Torta brownie rellena de dulce de leche y ganache de
												chocolate decorada con merengue.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Corteza de café grande</h4>
											<p>
												{" "}
												Tarta de chocolate, con dulce de café, mousse de
												chocolate y trozos de chocolate negro.
											</p>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-10">
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Cúpula de chocolate y merengue</h4>
											<p>
												Postre con base de merengue y mousse de chocolate (sin
												harina).
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Pie de manzana y pasas</h4>
											<p>Torta de manzana, pasas y canela.</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Brownie y dulce de leche (individual)</h4>
											<p>
												Torta brownie rellena de dulce de leche y ganache de
												chocolate decorada con merengue.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="single-slider">
							<div className="row justify-content-center">
								<h3
									style={{ marginBottom: "25px", textDecoration: "underline" }}
								>
									Dulces
								</h3>
							</div>
							<div className="row justify-content-center">
								<div className="col-lg-3 col-md-10">
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Corteza de café (individual)</h4>
											<p>
												Tarta de chocolate, con dulce de café, mousse de
												chocolate y trozos de chocolate negro.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Cúpula de chocolate (individual) </h4>
											<p>
												Postre con base de merengue y mousse de chocolate (sin
												harina).
											</p>
										</div>
									</div>{" "}
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Tarta de frutillas y maracuyá</h4>
											<p>Tarta con crema de maracuyá decorada con frutillas.</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Tarta de frutos secos</h4>
											<p>
												Tarta con base de flan, pasas y frutos secos tostados.
											</p>
										</div>
									</div>{" "}
								</div>
								<div className="col-lg-3 col-md-10">
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Tarta de manzana</h4>
											<p> Tarta de manzanas.</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Cheese cake</h4>
											<p> Torta de queso crema con frutos rojos.</p>
										</div>
									</div>

									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Mousse maracuyá (individual) </h4>
											<p>Postre con base de brownie y mousse de maracuyá.</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Pirámide almendrada (individual) </h4>
											<p>
												{" "}
												Postre con base de merengue almendrado y crema de dulce
												de leche y manteca.
											</p>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-10">
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Postre Flan (individual) </h4>
											<p> Base de bizcochuelo y flan con caramelo.</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Rogel (individual) </h4>
											<p>
												Finas capas de masa crocante rellenas de dulce de leche
												decorado con merengue.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Tarta de chocolate y naranja (individual) </h4>
											<p>Tarta de chocolate con crema de naranja y ganache.</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Tarta de coco y dulce de leche (individual) </h4>
											<p>Tarta rellena de crema de coco y dulce de leche.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-10">
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Tarta de frutos secos (individual) </h4>
											<p>
												Tarta con base de flan, pasas y frutos secos tostados.
											</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Tarta de manzana (individual) </h4>
											<p>Tarta de manzanas</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Tarta de maracuyá y frutillas (individual) </h4>
											<p>Tarta con crema de maracuyá decorada con frutillas.</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Tarta de pera y nuez (individual)</h4>
											<p>Tarta con masa de nueces, rellena de peras.</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="single-slider">
							<div className="row justify-content-center">
								<h3
									style={{ marginBottom: "25px", textDecoration: "underline" }}
								>
									Dulces
								</h3>
							</div>
							<div className="row justify-content-center">
								<div className="col-lg-4 col-md-10">
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Tarta frutas variadas (individual) </h4>
											<p>Tarta de crema pastelera, y frutas variadas.</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Lemon tart (individual)</h4>
											<p>Tarta de limón.</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Lemon tart con merengue (individual) </h4>
											<p>Tarta de limón con merengue.</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Tarta frutillas (individual) </h4>
											<p>Tarta de crema pastelera y frutillas.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-10">
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Quindím (individual) </h4>
											<p>Flan de coco.</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Cheese cake (individual) </h4>
											<p>Torta de queso crema con mermelada de frutos rojos.</p>
										</div>
									</div>
									<div className="single-menu-box ">
										<div className="menu-desc">
											<h4>Delicia de chocolate y frambuesa (individual) </h4>
											<p>
												Brownie con almendras tostadas y ganache de frambuesas.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Slider>
				</div>
				{/* Gallery */}
				{/* <div className="gallery-wrap">
          <div className="container">
          <SRLWrapper>
            <Slider className="row gallery-slider text-center" {...gallerysettings}>
            {dishPosts.map((item, i) => (
              <div key={i} className="col-lg-12">
                <a href={item.photo} className="gallery-popup" data-attribute="SRL">
                  <img src={item.photo} alt="" />
                </a>
              </div>
            ))}
            </Slider>
            </SRLWrapper>
          </div>
        </div> */}
				{/* Shape */}
				{/* <div className="shape-one">
          <img src="assets/img/icon/05.png" alt="" />
        </div> */}
				<div className="shape-two">
					<img src="assets/img/icon/06.png" alt="" />
				</div>
			</section>
		);
	}
}

export default Menuarea;
