import React, { Component } from "react";
import { Link } from "react-router-dom";
import "react-responsive-modal/styles.css";
import Family from "../homepage-two/Textblock";
import Banner from "./Banner";
import Info from "./Info";
import FoodMenu from "./FoodMenu";

class About extends Component {
	state = {
		open: false,
	};
	onOpenModal = () => {
		this.setState({ open: true });
	};

	onCloseModal = () => {
		this.setState({ open: false });
	};
	render() {
		const { open } = this.state;
		return (
			<section className="about-section pt-115 ">
				<div className="container">
					<Banner />
					<Info />
					<FoodMenu />
				</div>
			</section>
		);
	}
}

export default About;
