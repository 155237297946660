import React, { Component } from 'react';
import banner from '../../../assets/img/contact/breadcrumb-2.jpg';

class Breadcrumb extends Component {
  render() {
    return (
      <section className="breadcrumb-area" style={{backgroundImage: `url(${banner})`}}>
          <div className="container">
            <div className="breadcrumb-text">
              <span>Información</span>
              <h2 className="page-title">Contacto</h2>
            </div>
          </div>
        </section>
    );
  }
}

export default Breadcrumb;
