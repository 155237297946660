import React, { Component } from "react";
import { Link } from "react-router-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ReactWOW from "react-wow";
import InstitutionalVideo from "../homepage-three/Textblock";
import Family from "../homepage-two/Textblock";

class Weddings extends Component {
	state = {
		open: false,
	};
	onOpenModal = () => {
		this.setState({ open: true });
	};

	onCloseModal = () => {
		this.setState({ open: false });
	};
	render() {
		const { open } = this.state;
		return (
			<section className="about-section pt-115 pb-115">
				<div className="container">
					<div className="section-title about-title text-center">
						<span className="title-tag">
							<span>CASAMIENTOS</span>
						</span>
						<h4>
							Son muchos los que han confiado en nosotros su día mas importante.
							Estamos siempre a la vanguardia de las últimas tendencias en
							gastronomía para sorprender a tus invitados.
						</h4>
						<h4 style={{marginTop: '20px'}}>
							<strong>
								Agendá una reunión para conocer nuestra propuesta.
							</strong>
						</h4>
					</div>
				</div>
			</section>
		);
	}
}

export default Weddings;
