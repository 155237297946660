import React, { Component } from "react";
import Headerthree from "../layouts/Headerthree";
import Footertwo from "../layouts/Footertwo";
import Breadcrumb from "../sections/events/Breadcrumb";
import axios from "axios";

const EVENT_TYPES = [{
    id: '1', label: "Empresarial"
},
{ id: '2', label: "Boda" },
{ id: '3', label: "Fiesta de 15" },
{ id: '4', label: "Otro" },
]
class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventType: "",
            name: "",
            email: "",
            phone: "",
            possibleDate: "",
            amountPeople: null,
            place: "",
            duration: "",
            idea: "",
            message: "",
            loading: false,
            sent: false,
            formError: "",
        };
    }
    handleSubmit = async (e) => {
        e.preventDefault();

        const { eventType, name, email, phone, possibleDate, amountPeople, place, duration, idea, message } = this.state;
        const eventName = EVENT_TYPES.find(ev => ev.id == eventType)
        this.setState({ loading: true })
        if (name && email && phone) {
            try {
                await axios.post(
                    `https://nora-rey-backend.herokuapp.com/api/mailer/budget/`,
                    {
                        name: name,
                        eventType: eventName?.label || "",
                        phone: phone || "",
                        possibleDate: possibleDate || "",
                        amountPeople: amountPeople || "",
                        place: place || "",
                        duration: duration || "",
                        idea: idea || "",
                        email: email,
                        message: message || "",
                    }
                );
                this.setState({
                    name: "", email: "", message: "", eventType: "", phone: "", possibleDate: "",
                    amountPeople: "", place: "", duration: "", idea: "",
                    sent: true
                });
            } catch (e) {
                this.setState({
                    formError: "Ha ocurrido un error. Inténtelo nuevamente.",
                });
            }
            this.setState({ loading: false })

        } else {
            this.setState({ formError: "Debes completar todos los campos" });
        }
    };
    render() {
        return (
            <div>
                <Headerthree />
                <Breadcrumb />

                <section className="contact-part pt-100 pb-115">
                    <div className="container">
                        <div className="contact-info mb-50" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                            <h3 style={{
                                marginBottom: 20, color: '#bead8e',
                                fontWeight: '600'
                            }}>¿Estás pensando en contratar nuestros servicios para tu próximo evento?</h3>
                            <p>Rellena el formulario y te contactaremos para definir <strong>el mejor presupuesto </strong> que se adapte a tus <strong>necesidades</strong>.</p>
                        </div>
                        {/* Contact Form */}
                        <div className="contact-form">
                            <form onSubmit={this.handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="inputs-filed">
                                            <select name="guests" id="guests" value={this.state.eventType} onChange={({ target }) =>
                                                this.setState({ eventType: target.value })
                                            }>
                                                <option disabled={true} value="">
                                                    Tipo de evento
                                                </option>
                                                <option value={1}>Empresarial</option>
                                                <option value={2}>Boda</option>
                                                <option value={3}>Fiesta de 15</option>
                                                <option value={4}>Otro</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-group mb-30">
                                            <span className="icon">
                                                <p style={{
                                                    fontFamily: '"Roboto", sans-serif',
                                                    color: '#777777'
                                                }}>Fecha prevista: </p>
                                            </span>
                                            <input
                                                style={{ paddingLeft: '160px' }}
                                                type="date"
                                                placeholder="Fecha prevista"
                                                value={this.state.possibleDate}
                                                onChange={({ target }) =>
                                                    this.setState({ possibleDate: target.value })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-group mb-30">
                                            <span className="icon">
                                                <i className="far fa-user" />
                                            </span>
                                            <input
                                                type="text"
                                                placeholder="Nombre y Apellido"
                                                required
                                                value={this.state.name}
                                                onChange={({ target }) =>
                                                    this.setState({ name: target.value })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-group mb-30">
                                            <span className="icon">
                                                <i className="far fa-envelope" />
                                            </span>
                                            <input
                                                type="email"
                                                placeholder="Email"
                                                required
                                                value={this.state.email}
                                                onChange={({ target }) =>
                                                    this.setState({ email: target.value })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-group mb-30">
                                            <span className="icon">
                                                <i className="far fa-phone" />
                                            </span>
                                            <input
                                                type="phone"
                                                placeholder="Teléfono"
                                                required
                                                value={this.state.phone}
                                                onChange={({ target }) =>
                                                    this.setState({ phone: target.value })
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input-group mb-30">
                                            <span className="icon">
                                                <i className="far fa-plus-circle" />
                                            </span>
                                            <input
                                                type="number"
                                                min={1}
                                                placeholder="Cantidad de personas"
                                                value={this.state.amountPeople}
                                                onChange={({ target }) =>
                                                    this.setState({ amountPeople: target.value })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-group mb-30">
                                            <span className="icon">
                                                <i className="far fa-location-arrow" />
                                            </span>
                                            <input
                                                type="text"
                                                placeholder="Lugar del evento"
                                                value={this.state.place}
                                                onChange={({ target }) =>
                                                    this.setState({ place: target.value })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-group mb-30">
                                            <span className="icon">
                                                <i className="far fa-location-arrow" />
                                            </span>
                                            <input
                                                type="text"
                                                placeholder="Duración del evento"
                                                value={this.state.duration}
                                                onChange={({ target }) =>
                                                    this.setState({ duration: target.value })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="input-group textarea mb-30">
                                            <span className="icon">
                                                <i className="far fa-allergies" />
                                            </span>
                                            <textarea
                                                type="text"
                                                placeholder="Cuéntanos tu idea o preferencia de menú"
                                                required
                                                value={this.state.idea}
                                                onChange={({ target }) =>
                                                    this.setState({ idea: target.value })
                                                }
                                                defaultValue={""}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="input-group textarea mb-30">
                                            <span className="icon">
                                                <i className="far fa-pen" />
                                            </span>
                                            <textarea
                                                type="text"
                                                placeholder="Notas/Comentarios adicionales"
                                                style={{ height: '120px' }}
                                                value={this.state.message}
                                                onChange={({ target }) =>
                                                    this.setState({ message: target.value })
                                                }
                                                defaultValue={""}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 text-center">
                                        <button type="submit" className="main-btn btn-filled prueba">
                                            {this.state.loading ? 'Cargando...' : ' Solicitar presupuesto'}
                                        </button>
                                    </div>
                                    {this.state.sent && (
                                        <p
                                            style={{
                                                marginTop: "20px",
                                                textAlign: "center",
                                                width: "100%",
                                            }}
                                        >
                                            Gracias por contactarte con nosotros! A la brevedad estaremos comunicándonos contigo!
                                        </p>
                                    )}
                                    {this.state.formError && (
                                        <p
                                            style={{
                                                marginTop: "20px",
                                                textAlign: "center",
                                                width: "100%",
                                            }}
                                        >
                                            {this.state.formError}
                                        </p>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
                {/*====== CONTACT PART END ======*/}
                <Footertwo />
            </div >
        );
    }
}

export default Contact;
