import React, { Component } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import $ from "jquery";
import { findDOMNode } from "react-dom";
import logo from "../../assets/img/logo/logonora.png";
import boutique from "../../assets/img/logo/logoBoutique.png";
import info from "../../assets/img/icon/info-alt.svg";

let navigationmenu = [
	{
		id: 3,
		link: "/about",
		linkText: "Nosotros",
		child: false,
	},
	{
		id: 2,
		linkText: "Nuestros servicios",
		child: true,
		submenu: [
			{
				id: 21,
				link: "/",
				linkText: "Eventos",
				child: true,
				third_menu: [
					{
						id: 24,
						link: "/casamientos",
						linkText: "Casamientos",
					},
					{
						id: 24,
						link: "/familiares",
						linkText: "Familiares",
					},
					{
						id: 24,
						link: "/empresariales",
						linkText: "Empresariales",
					},
				],
			},
			{
				id: 22,
				link: "/institucional",
				linkText: "Institucional",
			},
			{
				id: 23,
				linkText: "Boutique",
				link: "/boutique",
			},
		],
	},
	{
		id: 7,
		link: "/contacto",
		linkText: "Contacto",
		child: false,
	},
	{
		id: 8,
		link: "/presupuesto",
		linkText: "Solicitar presupuesto",
		child: false,
	},
];

if (window.innerWidth < 600) {
	navigationmenu = [
		{
			id: 3,
			link: "/about",
			linkText: "Nosotros",
			child: false,
		},
		{
			id: 2,
			linkText: "Nuestros servicios",
			child: true,
			submenu: [
				{
					id: 24,
					link: "/casamientos",
					linkText: "Casamientos",
				},
				{
					id: 24,
					link: "/familiares",
					linkText: "Eventos Familiares",
				},
				{
					id: 24,
					link: "/empresariales",
					linkText: "Eventos Empresariales",
				},
				{
					id: 22,
					link: "/institucional",
					linkText: "Institucional",
				},
				{
					id: 23,
					linkText: "Boutique",
					link: "/boutique",
				},
			],
		},
		{
			id: 7,
			link: "/contacto",
			linkText: "Contacto",
			child: false,
		},
		{
			id: 8,
			link: "/presupuesto",
			linkText: "Solicitar presupuesto",
			child: false,
		},
	];
}

class Headerthree extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redText: false,
		};
		this.addClass = this.addClass.bind(this);
		this.removeClass = this.removeClass.bind(this);
		this.removeAll = this.removeAll.bind(this);
	}
	addClass() {
		this.setState({
			redText: true,
		});
	}

	removeClass() {
		this.setState({
			redText: false,
		});
	}
	removeAll() {
		this.setState({
			redText: false,
		});
	}
	componentDidMount() {
		window.addEventListener(
			"resize",
			() => {
				this.setState({
					isMobile: window.innerWidth < 1020,
				});
			},
			false
		);
		window.addEventListener(
			"load",
			() => {
				this.setState({
					isMobile: window.innerWidth < 1020,
				});
			},
			false
		);
	}
	handleToggle = () => {
		const el = findDOMNode(this.refs.toggle);
		const els = findDOMNode(this.refs.addClass);
		$(el).slideToggle();
		$(els).toggleClass("active");
	};
	navToggle = () => {
		const nv = findDOMNode(this.refs.navmenu);
		const nvb = findDOMNode(this.refs.navbtn);
		$(nv).toggleClass("menu-on");
		$(nvb).toggleClass("active");
	};
	removenavToggle = () => {
		const nv = findDOMNode(this.refs.navmenu);
		const nvb = findDOMNode(this.refs.navbtn);
		$(nv).removeClass("menu-on");
		$(nvb).removeClass("active");
	};

	getNextSibling = function (elem, selector) {
		// Get the next sibling element
		var sibling = elem.nextElementSibling;

		// If there's no selector, return the first sibling
		if (!selector) return sibling;

		// If the sibling matches our selector, use it
		// If not, jump to the next sibling and continue the loop
		while (sibling) {
			if (sibling.matches(selector)) return sibling;
			sibling = sibling.nextElementSibling;
		}
	};

	triggerChild = (e) => {
		let subMenu = "";

		subMenu =
			this.getNextSibling(e.target, ".submenu") !== undefined
				? this.getNextSibling(e.target, ".submenu")
				: null;

		if (subMenu !== null && subMenu !== undefined && subMenu !== "") {
			subMenu.classList = subMenu.classList.contains("open")
				? "submenu"
				: "submenu open";
		}
	};

	render() {
		const className = this.state.isMobile ? "breakpoint-on" : "";
		return (
			<div>
				{/*====== HEADER START ======*/}
				<header className="header-three">
					<div className="header-top" style={{ backgroundColor: "#092f26" }}>
						<div className="container container-custom-three"></div>
					</div>
					<div className="main-menu-area sticky-header">
						<div className="container container-custom-three">
							<div
								className={`nav-container d-flex align-items-center justify-content-between ${className}`}
							>
								{/* Site Logo */}
								<div className="site-logo">
									<Link to="/">
										<img src={logo} alt="" />
									</Link>
								</div>
								{/* Main Menu */}
								<div
									className="nav-menu d-lg-flex align-items-center justify-content-between"
									ref="navmenu"
								>
									{/* Navbar Close Icon */}
									<div className="navbar-close" onClick={this.removenavToggle}>
										<div className="cross-wrap">
											<span className="top" />
											<span className="bottom" />
										</div>
									</div>
									{/* Mneu Items */}
									<div className="menu-items">
										<ul>
											{navigationmenu.length > 0
												? navigationmenu.map((item, i) => (
														<li
															key={i}
															className={` ${
																item.child ? "menu-item-has-children" : ""
															} `}
															onClick={this.triggerChild}
														>
															{item.child ? (
																<Link
																	onClick={(e) => e.preventDefault()}
																	to="/"
																>
																	{" "}
																	{item.linkText}{" "}
																</Link>
															) : (
																<Link to={item.link}> {item.linkText} </Link>
															)}
															{item.child ? (
																<ul className="submenu" role="menu">
																	{item.submenu.map((sub_item, i) => (
																		<li
																			key={i}
																			className={`${
																				sub_item.child
																					? "menu-item-has-child"
																					: ""
																			} `}
																		>
																			{sub_item.child ? (
																				<Link
																					onClick={(e) => e.preventDefault()}
																					to="/"
																				>
																					{" "}
																					{sub_item.linkText}{" "}
																				</Link>
																			) : (
																				<Link to={sub_item.link}>
																					{" "}
																					{sub_item.linkText}{" "}
																				</Link>
																			)}
																			{sub_item.third_menu ? (
																				<ul className="submenu">
																					{sub_item.third_menu.map(
																						(third_item, i) => (
																							<li key={i}>
																								<Link to={third_item.link}>
																									{third_item.linkText}
																								</Link>
																							</li>
																						)
																					)}
																				</ul>
																			) : null}
																		</li>
																	))}
																</ul>
															) : null}
														</li>
												  ))
												: null}
											{window.innerWidth < 1020 && (
												<li>
													<a href="https://norareyboutiqueonline.com.uy">
														Boutique online
														<strong
															style={{ color: "#0e4437", marginLeft: "15px" }}
														>
															NUEVO!
														</strong>
													</a>
												</li>
											)}
											<li>
												<a
													className="mobile-hide"
													href="https://norareyboutiqueonline.com.uy"
												>
													<img style={{ height: "150px" }} src={boutique}></img>
												</a>
											</li>
										</ul>
									</div>
								</div>
								{/* navbar right content */}
								<div className="menu-right-buttons">
									{/* Log in icon */}
									{/* Off canvas Toggle */}
									<div className="toggle" onClick={this.addClass}>
										<Link to="#" className="special-a">
											<img src={info} className="mini-icon" />
										</Link>
									</div>
									{/* Navbar Toggler */}
									<div
										className="navbar-toggler"
										onClick={this.navToggle}
										ref="navbtn"
									>
										<span />
										<span />
										<span />
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>
				{/*====== HEADER END ======*/}
				{/*====== OFF CANVAS START ======*/}
				<div
					className={classNames("offcanvas-wrapper", {
						"show-offcanvas": this.state.redText,
					})}
				>
					<div
						className={classNames("offcanvas-overly", {
							"show-overly": this.state.redText,
						})}
						onClick={this.removeAll}
					/>
					<div className="offcanvas-widget">
						<Link to="#" className="offcanvas-close" onClick={this.removeClass}>
							<i className="fal fa-times" />
						</Link>
						{/* About Widget */}
						<div className="widget about-widget">
							<h5 className="widget-title">Servicios</h5>
							<p>
								Nora Rey es una empresa de <i>traiteur</i> que ofrece productos
								de alta calidad llevando una trayectoria de más de 45 años con
								inclinación hacia la cocina francesa. Cuenta con una boutique en
								Montevideo y ofrece sus productos online para tener una
								experiencia profesional desde el hogar. Participa de eventos
								familiares, empresariales y casamientos, estando presente en
								cada detalle.{" "}
							</p>
						</div>
						{/* Nav Widget */}
						<div className="widget nav-widget">
							<h5 className="widget-title">Locales y horarios</h5>
							<ul>
								<li>
									<p>
										<strong>
											Boutique Carrasco: Dr Alejandro Schroeder 6464
										</strong>
									</p>
									<p>Lun - Sab 10.00 - 20.00hrs | Tel: 093938770</p>
								</li>
								<li>
									<p>
										<strong>
											Boutique Pocitos: 26 de Marzo 1153
										</strong>
									</p>
									<p>Lun - Sab 10.00 - 20.00hrs | Tel: 093938870</p>
								</li>
								<li>
									<p>
										<strong>Planta de elaboración: Montevideo 2520</strong>
									</p>
									<p>Tel: 2601 8601</p>
								</li>
							</ul>
						</div>
						{/* Social Link */}
						<div className="widget social-link">
							<h5 className="widget-title">Encontranos en</h5>
							<ul>
								<li>
									<a
										href="https://www.facebook.com/NoraReyTraiteur/"
										target="_blank"
									>
										<i className="fab fa-facebook-f" />
									</a>
								</li>
								<li>
									<a
										href="https://www.instagram.com/norareytraiteur/"
										target="_blank"
									>
										<i className="fab fa-instagram" />
									</a>
								</li>
							</ul>
						</div>
						<div className="widget about-widget">
							<h5 className="widget-title">Contactanos</h5>
							<a href="mailto:info@norarey.com.uy">
								<strong>info@norarey.com.uy</strong>
							</a>
						</div>
					</div>
				</div>
				{/*====== OFF CANVAS END ======*/}
			</div>
		);
	}
}

export default Headerthree;
