import React, { Component } from "react";
import Headerthree from "../layouts/Headerthree";
import Footertwo from "../layouts/Footertwo";
import Breadcrumb from "../sections/contact/Breadcrumb";
import axios from "axios";

class Contact extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			email: "",
			message: "",
			sent: false,
			formError: "",
		};
	}
	handleSubmit = async (e) => {
		e.preventDefault();

		const { name, email, message } = this.state;
		if (name && email && message) {
			try {
				await axios.post(
					`https://nora-rey-backend.herokuapp.com/api/mailer/receive/`,
					{
						name: name,
						email: email,
						message: message,
					}
				);
				this.setState({ name: "", email: "", message: "", sent: true });
			} catch (e) {
				this.setState({
					formError: "Ha ocurrido un error. Inténtelo nuevamente.",
				});
			}
		} else {
			this.setState({ formError: "Debes completar todos los campos" });
		}
	};
	render() {
		return (
			<div>
				<Headerthree />
				<Breadcrumb />

				<section className="contact-part pt-115 pb-115">
					<div className="container">
						{/* Contact Info */}
						<div className="contact-info">
							<div className="row justify-content-center">
								<div className="col-lg-4 col-sm-6 col-10">
									<div className="info-box">
										<div className="icon">
											<i className="flaticon-home" />
										</div>
										<div className="desc">
											<h4>Dirección</h4>
											<p style={{fontSize: '15px'}}>Carrasco: Schroeder 6464</p>
											<p style={{fontSize: '15px'}}>Pocitos: 26 de marzo 1153</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-sm-6 col-10">
									<div className="info-box">
										<div className="icon">
											<i className="flaticon-phone" />
										</div>
										<div className="desc">
											<h4>Horario</h4>
											<p>Lunes a Sábado</p>
											<p>10.00 a 20.00hrs</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-sm-6 col-10">
									<div className="info-box">
										<div className="icon">
											<i className="flaticon-message" />
										</div>
										<div className="desc">
											<h4>Email & Teléfono</h4>
											<p>info@norarey.com.uy</p>
											<p>Carrasco: 093938770</p>
											<p>Pocitos 093938870</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* Contact Mapts */}
						<div className="contact-maps mb-30">
							<iframe
								src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Alejandro%20Schroeder%206464+Nora%20Rey%20Boutique&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
								title="title"
							/>
						</div>
						{/* Contact Form */}
						<div className="contact-form">
							<form onSubmit={this.handleSubmit}>
								<div className="row">
									<div className="col-md-6">
										<div className="input-group mb-30">
											<span className="icon">
												<i className="far fa-user" />
											</span>
											<input
												type="text"
												placeholder="Nombre completo"
												required
												value={this.state.name}
												onChange={({ target }) =>
													this.setState({ name: target.value })
												}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="input-group mb-30">
											<span className="icon">
												<i className="far fa-envelope" />
											</span>
											<input
												type="email"
												placeholder="Email"
												required
												value={this.state.email}
												onChange={({ target }) =>
													this.setState({ email: target.value })
												}
											/>
										</div>
									</div>
									<div className="col-12">
										<div className="input-group textarea mb-30">
											<span className="icon">
												<i className="far fa-pen" />
											</span>
											<textarea
												type="text"
												placeholder="Mensaje"
												required
												value={this.state.message}
												onChange={({ target }) =>
													this.setState({ message: target.value })
												}
												defaultValue={""}
											/>
										</div>
									</div>
									<div className="col-12 text-center">
										<button type="submit" className="main-btn btn-filled">
											Enviar mensaje
										</button>
									</div>
									{this.state.sent && (
										<p
											style={{
												marginTop: "20px",
												textAlign: "center",
												width: "100%",
											}}
										>
											Se ha enviado el mensaje. Te responderemos a la brevedad.
										</p>
									)}
									{this.state.formError && (
										<p
											style={{
												marginTop: "20px",
												textAlign: "center",
												width: "100%",
											}}
										>
											{this.state.formError}
										</p>
									)}
								</div>
							</form>
						</div>
					</div>
				</section>
				{/*====== CONTACT PART END ======*/}
				<Footertwo />
			</div>
		);
	}
}

export default Contact;
