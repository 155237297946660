import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Masonry from 'react-masonry-component';
import ReactWOW from 'react-wow'
import q1 from '../../../assets/img/quality/quality1.jpg';
import q2 from '../../../assets/img/quality/quality2.jpg';
import q3 from '../../../assets/img/quality/quality3.jpg';
import q4 from '../../../assets/img/quality/quality4.jpg';
import planta from '../../../assets/img/quality/planta-min.jpg';

class About extends Component {
  render() {
    return (
        <section className="about-section pt-115 pb-115 pb-115-with-img">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <ReactWOW animation="fadeInLeft" data-wow-delay=".3s">
            <div className="col-lg-6 col-md-10">
              <Masonry className="row about-features-boxes fetaure-masonary">
                <div className="col-sm-6">
                  <div className="single-feature-box only-bg" style={{backgroundImage: `url(${q4})`}}>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="single-feature-box only-bg mt-30" style={{backgroundImage: `url(${q1})`}}>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="single-feature-box only-bg mt-30" style={{backgroundImage: `url(${q3})`}}>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="single-feature-box only-bg mt-30" style={{backgroundImage: `url(${q2})`}}>
                  </div>
                </div>
              </Masonry>
            </div>
            </ReactWOW>
            <ReactWOW animation="fadeInRight" data-wow-delay=".3s">
            <div className="col-lg-6 col-md-8 col-sm-10">
              <div className="abour-text pl-50 pr-50">
                <div className="section-title mb-30">
                  <span className="title-tag">la empresa</span>
                  <h2>Nosotros</h2>
                </div>
                <p className=" darker-text">
                Nora Rey inicia su actividad como Chef en el año 1974, con orientación hacia la cocina francesa. Realiza stages en distintos institutos de Francia (Lyon y Paris), en la escuela gastronómica <i>Lenôtre</i>, en el <i>Château Du Fey</i> en Borgoña y en estos últimos años en el Centro de Formación Profesional de <i>Alain Ducasse</i>.
                </p>
                <p className="mt-10 darker-text">
                A lo largo de más de 45 años, ha atendido miles de eventos, desde la más pequeña reunión familiar a cenas de gala en honor a reyes, príncipes y presidentes, siempre con el mismo entusiasmo, profesionalismo y responsabilidad, estando en cada evento detrás del mínimo detalle.
                </p>
                <Link to="/about" className={"main-btn btn-filled mt-40 mobile-hide"}> Conozca la empresa</Link>
              </div>
            </div>
            </ReactWOW>
          </div>
        </div>
        <div className="about-right-bottom">
          <div className="about-bottom-img">
            <img src={planta} alt="" style={{verticalAlign: 'text-top'}}/>
          </div>
        </div>
      </section>
      
    );
  }
}

export default About;
