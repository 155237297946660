import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactWOW from "react-wow";
import p1 from "../../../assets/img/events/empresariales-min.jpg";
import p2 from "../../../assets/img/events/wedding-min.jpg";
import p3 from "../../../assets/img/events/familiares-min.jpg";

class Corefeature extends Component {
	render() {
		return (
			<section className="core-feature-section bg-white pt-115 ">
				<div className="container">
					<div className="section-title text-center mb-50">
						<span className="title-tag"> servicios </span>
						<h2>Eventos</h2>
					</div>
					{/* Featre Loop */}
					<div className="row features-loop">
						<div className="col-lg-4 col-sm-6 order-1">
							<ReactWOW animation="fadeInLeft" data-wow-delay=".3s">
								<div className="feature-box with-hover-img">
								<h3>
										<Link to="#">Consultanos por tu evento</Link>
									</h3>
									<p>
										Ofrecemos servicios de alta calidad para tu evento.
									</p>
									<div
										className="hover-img"
										style={{
											backgroundImage: `url(${p2})`,
										}}
									/>
								</div>
							</ReactWOW>
						</div>
						<div
							className="col-lg-4 col-sm-6 order-2"
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<ReactWOW animation="fadeInUp" data-wow-delay=".4s">
								<div
									className="feature-box "
									style={{
										width: "100%",
										height: "100%",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<h3>
										<Link to="/casamientos" style={{ color: "#134f40" }}>
											Casamientos
										</Link>
									</h3>
								</div>
							</ReactWOW>
						</div>
						<div className="col-lg-4 col-sm-6 order-3 order-sm-4 order-lg-3">
							<ReactWOW animation="fadeInRight" data-wow-delay=".5s">
								<div className="feature-box with-hover-img">
									<h3>
										<Link to="#">Consultanos por tu evento</Link>
									</h3>
									<p>Ofrecemos servicios de alta calidad para tu evento.</p>
									<div
										className="hover-img"
										style={{
											backgroundImage: `url(${p1})`,
										}}
									/>
								</div>
							</ReactWOW>
						</div>
						<div className="col-lg-4 col-sm-6 order-4 order-sm-3 order-lg-4">
							<ReactWOW animation="fadeInLeft" data-wow-delay=".7s">
								<div
									className="feature-box"
									style={{
										width: "100%",
										height: "100%",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<h3>
										<Link to="familiares" style={{ color: "#134f40" }}>
											Familiares
										</Link>
									</h3>
								</div>
							</ReactWOW>
						</div>
						<div className="col-lg-4 col-sm-6 order-5">
							<ReactWOW animation="fadeInUp" data-wow-delay=".9s">
								<div className="feature-box with-hover-img">
									<h3>
										<Link to="#">Consultanos por tu evento</Link>
									</h3>
									<p>Ofrecemos servicios de alta calidad para tu evento.</p>
									<div
										className="hover-img"
										style={{
											backgroundImage: `url(${p3})`,
										}}
									/>
								</div>
							</ReactWOW>
						</div>
						<div className="col-lg-4 col-sm-6 order-6">
							<ReactWOW animation="fadeInDown" data-wow-delay="1.1s">
								<div
									className="feature-box"
									style={{
										width: "100%",
										height: "100%",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<h3>
										<Link to="empresariales" style={{ color: "#134f40" }}>
											Empresariales
										</Link>
									</h3>
								</div>
							</ReactWOW>
						</div>
					</div>
					<div className="row justify-content-center">
						<Link to="/casamientos" className="main-btn btn-filled mt-80">
							Conocer más
						</Link>
					</div>
				</div>
			</section>
		);
	}
}

export default Corefeature;
