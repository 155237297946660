import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

import froomimg1 from '../../../assets/img/banner/copetinoriginal.jpg';
import froomimg2 from '../../../assets/img/banner/clasicos2.jpg';
import froomimg3 from '../../../assets/img/banner/tarta.jpg';
import froomimg4 from '../../../assets/img/banner/postres_alt.jpg';

const featureroomPosts = [
  {
      photo: froomimg1,
      title: 'Copetín',
      desc: 'Entre ellos se encuentran distintos patés, hummus, quesos artesanales, variedad de tostaditas, galletas y foccacias.',
      url: '/',
  },
  {
      photo: froomimg2,
      url: '/',
      title: 'Congelados',
      desc: 'Entre ellos se encuentran lasagnas, canelones, rótolos, pollos especiados, carnes, entre otros.',
  },
  {
    photo: froomimg3,
    url: '/',
    title: 'Tartas',
    desc: 'Variedad de tartas, por porción o enteras.',
  },
  {
    photo: froomimg4,
    url: '/',
    title: 'Postres',
    desc: 'Entre ellos se encuentran los clásicos como la rogel, tarta de manzana, tarta de chocolate y naranja y muchas cosas más.',
  },
];
function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="slick-arrow next-arrow" onClick={onClick}><i className="fal fa-arrow-right"></i></div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="slick-arrow prev-arrow" onClick={onClick}><i className="fal fa-arrow-left"></i></div>
  );
} 
class Roomslider extends Component {
  render() {
    const settings = {
      slidesToShow: 1,
				slidesToScroll: 1,
				fade: false,
				infinite: true,
				autoplay: false,
				autoplaySpeed: 4000,
				arrows: true,
				dots: false,
				centerMode: true,
        centerPadding: '28%',
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
				responsive: [
					{
						breakpoint: 1600,
						settings: {
							centerPadding: '20%',
						},
					},
					{
						breakpoint: 992,
						settings: {
							centerPadding: '15%',
						},
					},
					{
						breakpoint: 768,
						settings: {
							centerPadding: '10%',
						},
					},
					{
						breakpoint: 576,
						settings: {
							centerPadding: '5%',
						},
					},
				],
  }; 
    return (
        <section className="room-slider bg-white  pt-115 pb-100">
        <div className="container-fluid p-0">
          <div className="section-title mb-80 text-center">
            <span className="title-tag">productos</span>
            <h2>Boutique</h2>
          </div>
          <Slider className="row rooms-slider-two justify-content-center" {...settings}>
          {featureroomPosts.map((item, i) => (
            <div key={i} className="col-lg-12">
              <div className="single-rooms-box">
                <div className="room-img">
                  <div className="img" style={{backgroundImage: "url("+ item.photo + ")"}}>
                  </div>
                </div>
                <div className="room-desc">
                  <div className="row align-items-center">
                    <div className="col-sm-8">
                      <h3><Link to={item.url}>{item.title}</Link></h3>
                      <p>
                      {item.desc}
                      </p>
                    </div>
                    <div className="col-sm-4">
                      <div className="main-btn">
                        <a href="https://norareyboutiqueonline.com.uy">
                          Comprar
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          </Slider>
        </div>
      </section>
      
    );
  }
}

export default Roomslider;
