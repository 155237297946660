import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logoaux from '../../assets/img/logo/logo_aux.png';
import logo from "../../assets/img/logo/logonora.png";


class Footertwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
       redText: false
    };
  }
  componentDidMount() {
    window.addEventListener('scroll', () => {
      this.setState({
          isTop: window.scrollY > 300
      });
  }, false);
  }
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  render() {
    const className = this.state.isTop ? 'active' : '';
    return (
      <div>
        {/*====== Back to Top ======*/}
        {/* <Link to="#" className={`back-to-top ${className}`} id="backToTop" onClick={() => this.scrollToTop()}>
          <i className="fal fa-angle-double-up" />
        </Link> */}
        {/*====== FOOTER START ======*/}
        <footer className="footer-two">
          <div className="footer-widget-area pt-50 ">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-sm-6 order-1">
                  {/* Site Info Widget */}
                  <div className="widget site-info-widget mb-50">
                    <div className="footer-logo mb-50">
                      <img src={logoaux} alt="" />
                    </div>
                    <p>
                      Encontranos en:
                    </p>
                    <div className="social-links mt-40">
                      <a href="https://www.facebook.com/NoraReyTraiteur/" target="_blank"><i className="fab fa-facebook-f" /></a>
                      <a href="https://www.instagram.com/norareytraiteur/" target="_blank"><i className="fab fa-instagram" /></a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 order-3 order-lg-2">
                  {/* Nav Widget */}
                  <div className="widget nav-widget mb-50">
                    <div>
                      <h4 className="widget-title">Populares</h4>
                      <ul>
                        <li><a href="https://norareyboutiqueonline.com.uy/" style={{fontFamily: '"Raleway", sans-serif'}}><strong>TIENDA ONLINE</strong></a></li>
                        <li><Link to="/casamientos" style={{fontFamily: '"Raleway", sans-serif'}}>Casamientos</Link></li>
                        <li><Link to="/empresariales" style={{fontFamily: '"Raleway", sans-serif'}}>Eventos empresariales</Link></li>
                        <li><Link to="/familiares" style={{fontFamily: '"Raleway", sans-serif'}}>Eventos familiares</Link></li>
                        <li><Link to="/about" style={{fontFamily: '"Raleway", sans-serif'}}>La empresa</Link></li>
                        <li><Link to="/contacto" style={{fontFamily: '"Raleway", sans-serif'}}>Contacto</Link></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 order-2 order-lg-3">
                  {/* Contact Widget */}
                  <div className="widget contact-widget mb-50">
                    <h4 className="widget-title">Contacto</h4>
                    <div className="contact-lists">
                      <div className="contact-box">
                        <div className="icon">
                          <i className="flaticon-call" />
                        </div>
                        <div className="desc">
                          <h6 className="title">Teléfono</h6>
                          093938770 (Carrasco)
                          093938870 (Pocitos)
                        </div>
                      </div>
                      <div className="contact-box">
                        <div className="icon">
                          <i className="flaticon-message" />
                        </div>
                        <div className="desc">
                          <h6 className="title">Email</h6>
                          <Link to="#">info@norarey.com.uy</Link>
                        </div>
                      </div>
                      <div className="contact-box">
                        <div className="icon">
                          <i className="flaticon-location-pin" />
                        </div>
                        <div className="desc">
                          <h6 className="title">Dirección</h6>
                          Alejandro Schroeder 6464 /
                          26 de Marzo 1153.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright-area pt-30 pb-30">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-5 order-2 order-md-1">
                  {/* <p className="copyright-text copyright-two">Todos los derechos reservados Designed by <Link to="#"> Darwin Labs</Link> - 2020</p> */}
                  <p className="copyright-text copyright-two">Todos los derechos reservados - 2020</p>

                </div>
              </div>
            </div>
          </div>
        </footer>
        {/*====== FOOTER END ======*/}
      </div>
    );
  }
}

export default Footertwo;
