import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactWOW from "react-wow";

class Info extends Component {
	render() {
		return (
			<section className="cta-section pt-115 pb-160">
				<div className="container">
					<div className="cta-inner">
						<div className="row justify-content-center">
							<div className="col-lg-5 col-md-8 col-sm-9 col-10 order-2 order-lg-1">
								<div className="cta-text">
									<div className="section-title mb-20">
										<span className="title-tag">Boutique</span>
										<h2>Información</h2>
									</div>
									<Link to="/contact" className="main-btn btn-filled">
										Contáctenos
									</Link>
								</div>
							</div>
							<div className="col-lg-5 col-md-10 col-sm-11 col-10 order-1 order-lg-2">
								{/* feature loop */}
								<div className="cta-features">
									{/* feature box */}
									<ReactWOW animation="fadeInUp" data-wow-delay=".3s">
										<div className="single-feature ">
											<div className="icon">
												<i className="flaticon-location-pin" />
											</div>
											<div className="cta-desc">
												<h3>Dirección</h3>
												<p>Alejandro Schroeder 6464</p>
												<p>11500, Montevideo</p>
											</div>
										</div>
									</ReactWOW>
									{/* feature box */}
									<ReactWOW animation="fadeInUp" data-wow-delay=".4s">
										<div className="single-feature">
											<div className="icon">
												<i className="flaticon-clock" />
											</div>
											<div className="cta-desc">
												<h3>Horario de atención</h3>
												<p>
													Lunes a Sábado
												</p>
                        <p>
                          10.00 a 20.00hrs
                        </p>
											</div>
										</div>
									</ReactWOW>
									{/* feature box */}
									<ReactWOW animation="fadeInUp" data-wow-delay=".5s">
										<div className="single-feature">
											<div className="icon">
												<i className="flaticon-phone" />
											</div>
											<div className="cta-desc">
												<h3>Teléfono</h3>
												<p>
												093938770 (Carrasco)
												093938870 (Pocitos)
												</p>
											</div>
										</div>
									</ReactWOW>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default Info;
