import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import styled from "styled-components";
import { WhatsAppWidget } from "react-whatsapp-widget";

import Preloader from './components/layouts/Preloader';
import Homethree from './components/pages/Homethree';
import About from './components/pages/About';
import Boutique from './components/pages/Boutique';
import Weddings from './components/pages/Weddings';
import Familiars from './components/pages/Familiars';
import Business from './components/pages/Business';
import Institutional from './components/pages/Institutional';
import Contact from './components/pages/Contact';
import Events from './components/pages/Events';
import ScrollToTopCustom from "./components/layouts/ScrollToTop";
import TagManager from 'react-gtm-module'

import Pixel from './helpers/pixel';
import "react-whatsapp-widget/dist/index.css";
import './App.scss';

const WhatsappContainer = styled.div`
	position: fixed;
	z-index: 10000000;
	bottom: 10px;
  margin-bottom: 50px;
	right: -28px;
	background: rgb(79 206 93);
	border-radius: 20px;
	height: 95px;
	width: 130px;
	display: flex;
	justify-content: center;
	align-items: center;
  @media (max-width: 500px) {
    height: 90px;
    width: 110px;
  }
	> div {
		margin-bottom: 50px !important;
    right: 20px;
		@media (max-width: 500px) {
      margin-bottom: 55px !important;
      right: 15px;
		}
	}
`;


function App() {
  return (
    <Router>
      { <Preloader />}
      <Pixel />

      {/* <ScrollToTopCustom /> */}

      <Switch>
        <Route exact path='/' component={Homethree} />
        <Route path='/about' component={About} />
        <Route path='/casamientos' component={Weddings} />
        <Route path='/familiares' component={Familiars} />
        <Route path='/empresariales' component={Business} />
        <Route path='/boutique' component={Boutique} />
        <Route path='/institucional' component={Institutional} />
        <Route path='/contacto' component={Contact} />
        <Route path='/presupuesto' component={Events} />
      </Switch>
      <WhatsappContainer>
        <WhatsAppWidget
          phoneNumber="59893938681"
          companyName={"Nora Rey Traiteur"}
          replyTimeText={"Te responderemos en menos de 24hrs."}
          message={"Hola! 👋🏼 \n\nCómo podemos ayudarte?"}
          inputPlaceHolder={"Ej: Hola, me gustaría pedir un\n presupuesto."}
          sendButtonText={"Enviar"}
        />
      </WhatsappContainer>

    </Router>
  );
}

export default App;
