import React, { Component } from "react";
import Headerthree from "../layouts/Headerthree";
import Footertwo from "../layouts/Footertwo";
import Breadcrumb from "../sections/weddings/Breadcrumb";
import WeddingsPage from "../sections/weddings/Weddings";
import Slider from "../sections/weddings/Slider";

import roomimg1 from "../../assets/img/events/wedding/salons/elias-regules/1.jpeg";
import roomimg2 from "../../assets/img/events/wedding/salons/elias-regules/2.jpeg";
import roomimg3 from "../../assets/img/events/wedding/salons/elias-regules/3.jpeg";
import roomimg4 from "../../assets/img/events/wedding/salons/elias-regules/4.jpeg";
import roomimg5 from "../../assets/img/events/wedding/salons/elias-regules/5.jpeg";
import roomimg6 from "../../assets/img/events/wedding/salons/elias-regules/6.jpeg";
import roomimg7 from "../../assets/img/events/wedding/salons/elias-regules/7.jpeg";

import roomimg8 from "../../assets/img/events/wedding/salons/regency/1.jpeg";
import roomimg9 from "../../assets/img/events/wedding/salons/regency/2.jpeg";
import roomimg10 from "../../assets/img/events/wedding/salons/regency/4.jpeg";
import roomimg11 from "../../assets/img/events/wedding/salons/regency/6.jpeg";
import roomimg12 from "../../assets/img/events/wedding/salons/regency/9.jpeg";
import roomimg13 from "../../assets/img/events/wedding/salons/regency/10.jpeg";
import roomimg14 from "../../assets/img/events/wedding/salons/regency/7.jpeg";
import roomimg15 from "../../assets/img/events/wedding/salons/regency/11.jpeg";
import roomimg16 from "../../assets/img/events/wedding/salons/regency/12.jpeg";
import roomimg17 from "../../assets/img/events/wedding/salons/regency/13.jpeg";
import roomimg18 from "../../assets/img/events/wedding/salons/regency/14.jpeg";
import roomimg19 from "../../assets/img/events/wedding/salons/regency/15.jpeg";
import roomimg20 from "../../assets/img/events/wedding/salons/regency/16.jpeg";

import roomimg21 from "../../assets/img/events/wedding/salons/villa-domus/1.jpeg";
import roomimg22 from "../../assets/img/events/wedding/salons/villa-domus/2.jpeg";
import roomimg23 from "../../assets/img/events/wedding/salons/villa-domus/3.jpeg";
import roomimg24 from "../../assets/img/events/wedding/salons/villa-domus/4.jpeg";
import roomimg25 from "../../assets/img/events/wedding/salons/villa-domus/5.jpeg";
import roomimg26 from "../../assets/img/events/wedding/salons/villa-domus/6.jpeg";
import roomimg27 from "../../assets/img/events/wedding/salons/villa-domus/7.jpeg";
import roomimg28 from "../../assets/img/events/wedding/salons/villa-domus/8.jpeg";
import roomimg29 from "../../assets/img/events/wedding/salons/villa-domus/9.jpeg";
import roomimg30 from "../../assets/img/events/wedding/salons/villa-domus/10.jpeg";
import roomimg31 from "../../assets/img/events/wedding/salons/villa-domus/11.jpeg";

const roomimgPosts = [
	{
		photo: roomimg1,
	},
	{
		photo: roomimg2,
	},
	{
		photo: roomimg3,
	},
	{
		photo: roomimg4,
	},
	{
		photo: roomimg5,
	},
	{
		photo: roomimg6,
	},
	{
		photo: roomimg7,
	},
];

const roomimgPostsRegency = [
	{
		photo: roomimg8,
	},
	{
		photo: roomimg9,
	},
	{
		photo: roomimg10,
	},
	{
		photo: roomimg11,
	},
	{
		photo: roomimg12,
	},
	{
		photo: roomimg13,
	},
	{
		photo: roomimg14,
	},
	{
		photo: roomimg15,
	},
	{
		photo: roomimg16,
	},
	{
		photo: roomimg17,
	},
	{
		photo: roomimg18,
	},
	{
		photo: roomimg19,
	},
	{
		photo: roomimg20,
	},
];

const roomimgPostsVillaDomus = [
	{
		photo: roomimg21,
	},
	{
		photo: roomimg22,
	},
	{
		photo: roomimg23,
	},
	{
		photo: roomimg24,
	},
	{
		photo: roomimg25,
	},
	{
		photo: roomimg26,
	},
	{
		photo: roomimg27,
	},
	{
		photo: roomimg28,
	},
	{
		photo: roomimg29,
	},
	{
		photo: roomimg30,
	},
	{
		photo: roomimg31,
	},
];

const roomPosts = [
	{
		name: "Elías Regules",
		desc: "Montevideo, Uruguay",
	},
	{
		name: "Elías Regules",
		desc: "Montevideo, Uruguay",
	},
	{
		name: "Elías Regules",
		desc: "Montevideo, Uruguay",
	},
	{
		name: "Elías Regules",
		desc: "Montevideo, Uruguay",
	},
	{
		name: "Elías Regules",
		desc: "Montevideo, Uruguay",
	},
	{
		name: "Elías Regules",
		desc: "Montevideo, Uruguay",
	},
	{
		name: "Elías Regules",
		desc: "Montevideo, Uruguay",
	},
];

const roomPostsRegency = [
	{
		name: "Regency Hotel",
		desc: "Montevideo, Uruguay",
	},
	{
		name: "Regency Hotel",
		desc: "Montevideo, Uruguay",
	},
	{
		name: "Regency Hotel",
		desc: "Montevideo, Uruguay",
	},
	{
		name: "Regency Hotel",
		desc: "Montevideo, Uruguay",
	},
	{
		name: "Regency Hotel",
		desc: "Montevideo, Uruguay",
	},
	{
		name: "Regency Hotel",
		desc: "Montevideo, Uruguay",
	},
	{
		name: "Regency Hotel",
		desc: "Montevideo, Uruguay",
	},
	{
		name: "Regency Hotel",
		desc: "Montevideo, Uruguay",
	},
	{
		name: "Regency Hotel",
		desc: "Montevideo, Uruguay",
	},
	{
		name: "Regency Hotel",
		desc: "Montevideo, Uruguay",
	},
	{
		name: "Regency Hotel",
		desc: "Montevideo, Uruguay",
	},
	{
		name: "Regency Hotel",
		desc: "Montevideo, Uruguay",
	},
];

const roomPostsVillaDomus = [
	{
		name: "Villa Domus",
		desc: "Canelones, Uruguay",
	},
	{
		name: "Villa Domus",
		desc: "Canelones, Uruguay",
	},
	{
		name: "Villa Domus",
		desc: "Canelones, Uruguay",
	},
	{
		name: "Villa Domus",
		desc: "Canelones, Uruguay",
	},
	{
		name: "Villa Domus",
		desc: "Canelones, Uruguay",
	},
	{
		name: "Villa Domus",
		desc: "Canelones, Uruguay",
	},
	{
		name: "Villa Domus",
		desc: "Canelones, Uruguay",
	},
	{
		name: "Villa Domus",
		desc: "Canelones, Uruguay",
	},
	{
		name: "Villa Domus",
		desc: "Canelones, Uruguay",
	},
	{
		name: "Villa Domus",
		desc: "Canelones, Uruguay",
	},
	{
		name: "Villa Domus",
		desc: "Canelones, Uruguay",
	},
];

class About extends Component {
	render() {
		return (
			<div>
				<Headerthree />
				<Breadcrumb />
				<WeddingsPage />
				<Slider
					roomimgPosts={roomimgPostsRegency}
					roomPosts={roomPostsRegency}
				/>
				<Slider
					roomimgPosts={roomimgPostsVillaDomus}
					roomPosts={roomPostsVillaDomus}
				/>
				<Slider roomimgPosts={roomimgPosts} roomPosts={roomPosts} />

				<Footertwo />
			</div>
		);
	}
}

export default About;
