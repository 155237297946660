import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Helmet } from 'react-helmet';

import {BrowserRouter} from 'react-router-dom'
import * as serviceWorker from './serviceWorker';

import './assets/css/animate.min.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './assets/css/font-awesome.min.css';
import './assets/css/flaticon.css';
import "../node_modules/slick-carousel/slick/slick.css"; 
import "../node_modules/slick-carousel/slick/slick.css";
import './assets/css/default.css';
import './assets/css/style.css';

ReactDOM.render(
    <BrowserRouter>
      <Helmet>
        <meta name="facebook-domain-verification" content="929rcj22q4sdzzzbng686gtu0kceol" />
      </Helmet>
      <Helmet>
      <meta name="google-site-verification" content="3TkyOcBsALEduny68JC-7lmNIwLVt6rkfPV_TMSLpY8" />
<script async src="https://www.googletagmanager.com/gtag/js?id=UA-174633138-1">
</script>
<script>
  {`window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'UA-174633138-1');`}
</script>
      </Helmet>
    <App />
    </BrowserRouter>,
  document.getElementById('nora')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

