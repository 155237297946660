import React, { Component } from "react";
import { Link } from "react-router-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ReactWOW from "react-wow";
import InstitutionalVideo from "../homepage-three/Textblock";
import Family from "../homepage-two/Textblock";

class Familiars extends Component {
	state = {
		open: false,
	};
	onOpenModal = () => {
		this.setState({ open: true });
	};

	onCloseModal = () => {
		this.setState({ open: false });
	};
	render() {
		const { open } = this.state;
		return (
			<section className="about-section pt-115 pb-115">
				<div className="container">
					<div className="section-title about-title text-center">
						<span className="title-tag">
							<span>EVENTOS EMPRESARIALES</span>
						</span>
						<h4>
							Lanzamientos, inauguraciones, aniversarios empresariales o cuando
							haya un motivo para festejar. Lo ayudamos en todos los detalles
							para que su evento esté acorde a lo que planificó. Nuestro
							personal de Ventas y Servicio lo asesorará desde la propuesta del
							menú gastronómico, hasta las bebidas, vajilla, decoración,
							mantelería y todo aquello que lo haga ser un invitado más en su
							fiesta.
						</h4>
						<h4 style={{ marginTop: "20px" }}>
							<strong>
								Agendá una reunión para conocer nuestra propuesta.
							</strong>
						</h4>
					</div>
				</div>
			</section>
		);
	}
}

export default Familiars;
