import React, { Component } from "react";
import { Link } from "react-router-dom";
import boutique from "../../../assets/img/logo/logoBoutique.png";

class Bookingform extends Component {
	render() {
		const isMobile = window.innerWidth < 1020;

		return (
			<section className="booking-form booking-form-dark">
				<div className="container">
					<div className="booking-form-inner">
						<div className="row align-items-center">
							<div className="col-lg-10 col-sm-6">
								<div className="inputs-filed mt-30">
									<h3 className="white-text text-align-center">
										Comprá nuestros productos desde la comodidad de tu casa.
									</h3>
								</div>
							</div>
							<div className="col-lg-2 col-md-6">
								{!isMobile ? (
									<div
										className="inputs-filed mt-30 justify-content-center"
										style={{ display: "flex" }}
									>
										<a
											href="https://norareyboutiqueonline.com.uy"
											className="main-btn btn-filled"
										>
											Comprar
										</a>
									</div>
								) : (
									<a
										href="https://norareyboutiqueonline.com.uy"
										style={{ margin: "auto 20%" }}
									>
										<img style={{ height: "150px" }} src={boutique}></img>
									</a>
								)}
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default Bookingform;
