import React, { Component } from "react";
import { Link } from "react-router-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ReactWOW from "react-wow";
import bck from "../../../assets/img/about/serving.jpg";
import videoInstitucional from '../../../assets/img/videos/videoinstitucional.mp4';

class InstitutionalVideo extends Component {
	state = {
		open: false,
	};
	onOpenModal = () => {
		this.setState({ open: true });
	};

	onCloseModal = () => {
		this.setState({ open: false });
	};
	render() {
		const { open } = this.state;
		return (
			<section className="text-block bg-black with-pattern pt-95 pb-95 pr-80 pl-80">
				<div className="container">
					<div className="row align-items-center justify-content-center">
						<div className="col-lg-6 col-md-10 order-2 order-lg-1">
							<div className="block-text">
								<div className="section-title mb-20">
									<span className="title-tag">Video institucional</span>
									<h2>Calidad &amp; excelencia.</h2>
								</div>
								<p className="pr-50">
									Nora Rey inicia su actividad como Chef en el año 1974, con
									orientación hacia la cocina francesa. Realiza stages en
									distintos institutos de Francia (Lyon y Paris), en la escuela
									gastronómica “Lenôtre”, en el “Château Du Fey” en Borgoña y en
									estos últimos años en el Centro de Formación Profesional de
									“Alain Ducasse”.{" "}
								</p>
                <p className="mt-10 ">
									A lo largo de más de 45 años, ha atendido miles de eventos,
									desde la más pequeña reunión familiar a cenas de gala en honor
									a reyes, príncipes y presidentes, siempre con el mismo
									entusiasmo, profesionalismo y responsabilidad, estando en cada
									evento detrás del mínimo detalle.
								</p>
							</div>
						</div>
						<ReactWOW animation="fadeInRight" data-wow-delay=".3s">
							<div className="col-lg-6 col-md-10 order-1 order-lg-2">
								<div
									className="video-wrap video-wrap-two mb-small"
									style={{ backgroundImage: `url(${bck})` }}
								>
									<div className="popup-video" onClick={this.onOpenModal}>
										<i className="fas fa-play" />
									</div>
									<Modal
										open={open}
										onClose={this.onCloseModal}
										center
										classNames={{
											modal: "video-modal",
										}}
									>
										<iframe
											src={videoInstitucional}
											title="title"
										></iframe>
									</Modal>
								</div>
							</div>
						</ReactWOW>
					</div>
				</div>
				<div className="pattern-wrap">
					<div className="pattern" />
				</div>
			</section>
		);
	}
}

export default InstitutionalVideo;
