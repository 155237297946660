import React, { Component } from "react";

import { Link, animateScroll as scroll } from "react-scroll";

import ReactWOW from "react-wow";
import background from '../../../assets/img/about/about2.jpg'
class Banner extends Component {
	render() {
		return (
			<section
				className="room-gallery-cta"
			>
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-10">
							<div className="cta-text text-center">
								<h4 className="gallery-content">
									En nuestra boutique elaboramos diariamente una variedad de
									productos para que puedas disfrutarlos en la comodidad de tu
									casa.
								</h4>
								<h4 className="gallery-content">
									Contamos con productos frescos como postres, tartas,
									empanadas, copetín y nuestra reconocida sección de congelados
									que son una solución a la hora de almorzar o cenar en familia
									o con amigos.
								</h4>
                <h3 className="gallery-heading">
                TE CONVERTIMOS EN EL MEJOR CHEF Y ANFITRIÓN.
                </h3>
								<ul className="mt-50">
									<ReactWOW animation="fadeInUp" data-wow-delay=".3s">
										<li>
											<Link className="main-btn btn-filled" to="menu" smooth={true}>
												Ver el menú
											</Link>
										</li>
									</ReactWOW>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default Banner;
