import React, { Component } from "react";
import Headerfour from "../layouts/Headerfour";
import { Link } from "react-router-dom";
import ReactWOW from "react-wow";

import galleryimg1 from "../../assets/img/about/about6.jpg";
import galleryimg2 from "../../assets/img/boutique/gallery1.jpg";
import galleryimg3 from "../../assets/img/about/about4.jpg";
import galleryimg4 from "../../assets/img/boutique/gallery2.jpg";
import galleryimg5 from "../../assets/img/boutique/gallery3.jpg";
import galleryimg6 from "../../assets/img/about/about1.jpg";
import galleryimg7 from "../../assets/img/boutique/gallery4.jpg";
import galleryimg8 from "../../assets/img/boutique/gallery5.jpg";

const galleryPosts = [
	{
		photo: galleryimg1,
		anitime: ".3s",
	},
	{
		photo: galleryimg2,
		anitime: ".4s",
	},
	{
		photo: galleryimg3,
		anitime: ".5s",
	},
	{
		photo: galleryimg4,
		anitime: ".6s",
	},
	{
		photo: galleryimg5,
		anitime: ".7s",
	},
	{
		photo: galleryimg6,
		anitime: ".8s",
	},
	{
		photo: galleryimg7,
		anitime: ".9s",
	},
	{
		photo: galleryimg8,
		anitime: "1.0s",
	},

];
class Gallery extends Component {
	render() {
		return (
			<div>
				<section className="gallery-wrappper pb-120">
					<div className="container">
						<div className="gallery-loop">
							{galleryPosts.map((item, i) => (
								<ReactWOW
									key={i}
									animation="fadeInUp"
									data-wow-delay={item.anitime}
								>
									<div className="single-gallery-image">
										<Link to={item.photo} className="popup-image">
											<img src={item.photo} alt="Images" />
										</Link>
									</div>
								</ReactWOW>
							))}
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default Gallery;
