import React, { Component } from "react";
import Headerthree from "../layouts/Headerthree";
import Footertwo from "../layouts/Footertwo";
import Mainbanner from "../sections/homepage-three/Banner";
import Bookingform from "../sections/homepage-three/Bookingform";
import About from "../sections/homepage-three/About";
import Roomslider from "../sections/homepage-three/Roomslider";
import Corefeature from "../sections/homepage-two/Corefeature";

class Homethree extends Component {
	render() {
		return (
			<div>
				<Headerthree />
				{/*====== BANNER PART START ======*/}
				<Mainbanner />
				{/*====== BANNER PART ENDS ======*/}
				{/*====== BOOKING FORM START ======*/}
				<Bookingform />
				{/*====== BOOKING FORM END ======*/}
				{/*====== ABOUT SECTION START ======*/}
				<About />
				{/*====== ABOUT FORM END ======*/}
				{/*====== ROOM SECTION START ======*/}
				{/* <Roomgallery/>  */}
				{/*====== ROOM SECTION END ======*/}
				{/*====== CORE FEATURES START ======*/}
				<Corefeature />
				{/*====== CORE FEATURES END ======*/}
				{/*====== ROOM SLIDER START ======*/}
				<Roomslider />
				{/*====== ROOM SLIDER END ======*/}
				<Footertwo />
			</div>
		);
	}
}

export default Homethree;
